
.dropdown-contents-base {
	width: 100%;
}

.dropdown-contents-top {
	position: relative;
	width: 100%;
	top: -4px;
	
	.dropdown-contents {
		position: absolute;
		z-index: 2;
		width: 100%;
		background-color: white;
		overflow-x: hidden;
		overflow-y: scroll;
		max-height: 40em;
		top: 4px;
		border: 1px solid #ced4da;
		border-radius: 0.25rem;
		
		li {
			display: block;
			cursor: pointer;
			user-select: none;
			padding: .375rem .75rem;
		}

		li:hover, li:focus {
			background-color: #e9e8e6;
		}
	}
}

