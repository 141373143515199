
.component-order-card {

	.cost {
		float: right;
	}
}

.component-order-card:hover {
	user-select: none;
	cursor: pointer;
}

.component-order-card-button {
	float: right;
}
