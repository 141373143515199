
.component-product-info {

	.product-amount-select {

		div {
			display: inline-block;

			border-style: solid none;
			border-color: #b4b3b2;
			border-width: thin;

			user-select: none;
			text-align: center;
		}

		div.counter {
			width: calc(100% - 120px);
		}

		div.counter input
		{
			background-color: #eceaea;
			padding: 16px;
			display: inline-block;

			margin: 0;
			border: none;

			overflow: hidden;
			height: 100%;
			width: 100%;

			text-align: center;
		}

		div.counter input:hover {
			background-color: #e2e1df;
		}

		div.adder button {
			background-color: #e4dede;
			cursor: pointer;
			
			width: 60px;
			border: none;
			padding: 16px;
		}

		div.adder button:hover {
			background-color: #dbd5d5;
		}
	}
}