
.page-cart
{
	th, tr.highlighted td {
		background-color: #e9e6de;
	}

	th.total {
		width: 200px;
	}

	th, td:not(.product-cart-image) {
		padding: 32px;

		p, h1, h2, h3, h4, h5, span {
			margin: 0;
		}

		a {
			color: #221e1f;
		}
	}

	.product-confirm {
		th, td {
			padding: 16px;
		}
	}

	table {
		border-width: thin;
		border-color: #b4b3b2;
		border-style: solid;
		width: 100%;
	}

	.product-cart-image {
		width: 138px;
	}

	img.product-image {
		height: 120px;
		width: 120px;
	}

	.cart-items-phone {
		text-align: center;
	}

	.cart-item {
		background-color: #eeeae5;
		padding: 20px;
		display: block;
		border-width: thin;
		border-color: #b4b3b2;
		border-style: solid;
		margin: 10px 0;
		
		img.product-image {
			width: 200px;
			height: 200px;
		}

		a {
			color: #221e1f;
		}

		input {
			width: 80px;
			display: inline-block;
		}
	}

	.cart-section {
		border-width: thin;
		border-color: #b4b3b2;
		border-style: solid;
		padding: 20px;
		width: 100%;
		margin-top: 20px;
	}
}