
.component-image-selector
{
	.images {
		overflow-x: hidden;
		overflow-y: auto;
		width: 100%;

		div.image {
			padding: 2px;
			margin: 2px;
			display: inline-block;
			height: 260px;
			width: 200px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: 50%;
			}
		}

		div.image img:hover {
			background-color: #dddcd5;
			opacity: 0.92;
			cursor: pointer;
		}
	}

	.file-upload {
		display: none;
	}

	.buttons {
		padding-top: 4px;
	}
}