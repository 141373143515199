
.component-notice
{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 2;
	pointer-events: none;

	.component-notice-list
	{
		float: right;

		.component-notice-card
		{
			width: 400px;
			max-width: calc(100vw - 32px);
			margin: 16px;
			padding: 16px;
			background-color: white;
			border: 1px solid black;
			user-select: none;
			pointer-events: auto;
			display: block;
		}
	}
}
