
.component-stripe-payment {
	.stripeInput {
		display: block;
		width: 100%;
		height: calc(1.5em + .75rem + 2px);
		padding: .375rem .75rem;
		color: #495057;
		background-color: #fff;
		background-clip: padding-box;
		border: 1px solid #ced4da;
		border-radius: .25rem;
		transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
		margin-bottom: 4px;
	}

	.firstlast-name {
		input {
			margin-bottom: 4px;
		}
	}
}

.component-stripe-payment .row {
	margin: 4px 0;
}