
.component-navbar
{
	.goulds-logo {
		max-height: 160px;
		max-width: 100%;
		padding: 20px 0;
		display: block;
		margin: 0 auto;
	}

	.nav-items {

		display: block;
		text-align: center;
		padding: 0;
		margin: 0;
		margin-bottom: 24px;

		ul {
			margin: 0;
			padding: 0;
		}

		li.last {
			border-style: none solid none solid;
		}

		li {
			display: inline-block;
			border-style: none none none solid;
			border-color: #221e1f;
			border-width: thin;
			text-align: center;

			a, p {
				text-decoration: none;
				color: #221e1f;
				padding: 14px;
				text-align: center;
				display: inline-block;
				width: 160px;
				cursor: pointer;
				margin: 0;
				user-select: none;
			}

			a:hover, p:hover {
				background-color: #eceaea;
			}
		}

		li.selected a, li.selected p {
			background-color: #eceaea;
		}

		li.selected a:hover, li.selected p:hover {
			background-color: #e2e1df;
		}

		li.phone {
			border-style: none;
			border-top-style: solid;
			display: inline-block;
			width: 80%;
			text-align: center;

			a, p {
				width: 100%;
			}
		}

		li.phone.last {
			border-bottom-style: solid;
		}
	}
}