
.component-product
{
	padding: 8px;
	margin: 8px;

	width: 232px;

	div.image {
		display: block;
		text-align: center;
	}

	.product-heading {
		height: 88px;
		h5 {
			overflow: hidden;
			text-align: center;
		}
	}

	.product-repeats {
		height: 8px;
	}

	p {
		margin-top: 24px;
	}

	border-style: solid none;
	border-color: #b4b3b2;
	border-width: thin;

	display: inline-block;
	user-select: none;
}

.component-product.product-disabled {
	background-color: #e9e6de;
}

.component-product.product-enabled:hover {
	background-color: #e2e1df;
	cursor: pointer;
}