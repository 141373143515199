
.component-side-bar
{
	padding: 0 20px;
	border-style: none;
	border-color: #221e1f;
	border-width: thin;
	margin: 0 20px;
	display: block;
	width: 400px;
	text-align: center;

	h5 {
		border-style: none;
		border-color: #221e1f;
		border-width: thin;
		padding: 8px;
		margin: 0;
	}

	li, ul {
		display: block;
		text-decoration: none;
		width: 100%;
		border-style: none;
		border-color: #221e1f;
		border-width: thin;
	}

	> ul {
		border-style: solid none solid none;
	}
	
	ul {
		margin: 0;
		padding: 0;

		> li {
			border-bottom-style: solid;
		}
		
		> li:last-child {
			border-bottom-style: none;
		}
	}

	li.side-bar-clickable {
		color: #221e1f;
		padding: 0.5em;

		margin: 0;
		margin-bottom: 0;
		cursor: pointer;
	}

	li.side-bar-dropdown {
		> ul {
			background-color: #fafaf8;
		}
	}
	
	li.side-bar-clickable:hover {
		background-color: #eceaea;
	}

	li.side-bar-clickable.selected {
		background-color: #eceaea;
	}

	li.side-bar-clickable.selected:hover {
		background-color: #e2e1df;
	}
}

.component-side-bar.phone {
	width: 100%;
	display: block;
	text-align: center;
	margin: 0;
	padding: 0 20px;
	padding-bottom: 32px;

	li {
		width: 100%;
		display: block;

		p {
			width: 100%;
		}
	}
}

.component-side-bar-window {

	div.csbw-left, div.csbw-right {
		display: inline-block;
	}

	div.csbw-left.phone, div.csbw-right.phone {
		display: block;
		width: 100%;
	}

	div.csbw-left:not(.phone) {
		float: left;
		width: calc(100% - 480px);
		margin: 0 40px;
	}

	div.csbw-right:not(.phone) {
		float: right;
		width: 400px;
		position: absolute;
		right: 20px;
	}
}
