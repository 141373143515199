
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

p, h1, h2, h3, h4, h5, li, label, span {
	color: #221e1f;
}

button.btn {
	margin-right: 4px;
	margin-bottom: 4px;
}

table {
	width: 100%;
	button {
		margin: 0 !important;
	}
}

h1 button, h2 button, h3 button, h4 button, h5 button {
	margin-left: 40px;
}

.link-subtle {
	cursor: pointer;
	text-decoration: underline;
}

.link {
	cursor: pointer;
	text-decoration: underline;
	color: #125dcc;
}

.confirm-box-bg
{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #000;
	opacity: 0.5;
	z-index: 10;
}

.confirm-box-fg {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #f7eeee;
	padding: 40px 0;
	margin: calc(100vw / 32);
	z-index: 11;
	overflow-x: hidden;
	overflow-y: auto;
}

.side-flex {
	display: flex;
	margin-bottom: 4px;
	width: 100%;
	> * {
		margin: 0 4px 0 0;
	}
	> *:last-child {
		margin: 0;
	}
	@media (max-width: 900px) {
	    flex-direction: column;
	}
}

table.borders {
	div.col, div.row {
		border: 1px black none;
	}
	div.col {
		border-style: none solid solid none;
	}
	div.col:first-child {
		border-left-style: solid;
	}
	div.row:first-child {
		border-top-style: solid;
	}
}

.right-x-button {
	border-style: none;
	background: none;
	margin: 0px 0px 0px 10px;
	padding: 0px;
	float: right;
	color: rgb(100, 0, 0);
}

label, small.form-text {
	line-height: 12px;
}

small.form-text {
	height: 12px;
}

.spacer {
	margin: 40px 0;
}

img.product-image {

	width: 200px;
	height: 200px;

	object-fit: cover;
	object-position: 50%;

	margin: 8px;
	padding: 0;
}

a.draftJsLinkifyPlugin__link__2ittM span {
	color: #007bff;
}

a.draftJsLinkifyPlugin__link__2ittM:hover span {
	color: #0056b3;
}

a.draftJsLinkifyPlugin__link__2ittM:visited span {
	color: #5e93c5;
}

.scroll-window {
	max-height: 440px;
	overflow-y: auto;
}
