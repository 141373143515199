.page-order
{
	th, tr.highlighted td {
		background-color: #e9e6de;
	}

	th, td {
		padding: 16px;

		p, h1, h2, h3, h4, h5, span {
			margin: 0;
		}

		a {
			color: #221e1f;
		}
	}

	table {
		border-width: thin;
		border-color: #b4b3b2;
		border-style: solid;
		width: 100%;
	}

	.item-clickable {
		display: inline;
	}

	.item-clickable:hover {
		text-decoration: underline;
		cursor: pointer;
		user-select: none;
	}
}