
.component-user-card
{
	background-color: #eceaea;
	padding: 20px;
	margin: 10px 0;
	border-style: solid none;
	border-color: #b4b3b2;
	border-width: thin;
}

.component-user-card.is-clickable {
	user-select: none;
	cursor: pointer;
}

.component-user-card:hover {
	background-color: #e2e1df;
}

