
.component-text-editor {

	.public-DraftEditor-content {
		display: block;
		width: 100%;
		padding: .375rem .75rem;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
		color: #495057;
		background-color: #fff;
		background-clip: padding-box;
		border: 1px solid #ced4da;
		border-radius: .25rem;
		transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

		min-height: 8rem;
		//overflow: auto;
	}
}