.component-big-button {
	width: 100%;
	padding: 20px;
	margin: 0;

	border-style: solid none;
	border-color: #b4b3b2;
	border-width: thin;

	background-color: #eceaea;
	user-select: none;

	h5, p {
		text-align: center;
		margin: 0;
		padding: 0;
		height: 100%;
	}
}

.component-big-button.disabled {
	background-color: #e9e6de;

	h5, p {
		color: #807377;
	}
}

.component-big-button:hover:not(.disabled) {
	background-color: #e2e1df;
	cursor: pointer;
}